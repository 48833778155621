export const FullDynamic = () => import('../../components/hyper/index/full_dynamic.vue' /* webpackChunkName: "components/full-dynamic" */).then(c => wrapFunctional(c.default || c))
export const HeaderNo = () => import('../../components/hyper/header/header_no.vue' /* webpackChunkName: "components/header-no" */).then(c => wrapFunctional(c.default || c))
export const DynamicBuilder = () => import('../../components/hyper/navBar/dynamic_builder.vue' /* webpackChunkName: "components/dynamic-builder" */).then(c => wrapFunctional(c.default || c))
export const FooterDynamic = () => import('../../components/hyper/footer/footer_dynamic.vue' /* webpackChunkName: "components/footer-dynamic" */).then(c => wrapFunctional(c.default || c))
export const ZaxosItem = () => import('../../components/hyper/item/zaxos_item.vue' /* webpackChunkName: "components/zaxos-item" */).then(c => wrapFunctional(c.default || c))
export const ProductHyper = () => import('../../components/hyper/product/product_hyper.vue' /* webpackChunkName: "components/product-hyper" */).then(c => wrapFunctional(c.default || c))
export const ItemsBookEndless = () => import('../../components/hyper/items/items_book_endless.vue' /* webpackChunkName: "components/items-book-endless" */).then(c => wrapFunctional(c.default || c))
export const EsDefault = () => import('../../components/hyper/itemsElastic/es_default.vue' /* webpackChunkName: "components/es-default" */).then(c => wrapFunctional(c.default || c))
export const MobileNo = () => import('../../components/hyper/mobile/mobile_no.vue' /* webpackChunkName: "components/mobile-no" */).then(c => wrapFunctional(c.default || c))
export const Default = () => import('../../components/hyper/account/default.vue' /* webpackChunkName: "components/default" */).then(c => wrapFunctional(c.default || c))
export const Cart = () => import('../../components/hyper/cart/cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c))
export const ErrorDefault = () => import('../../components/hyper/error/error_default.vue' /* webpackChunkName: "components/error-default" */).then(c => wrapFunctional(c.default || c))
export const TrackDefault = () => import('../../components/hyper/track/track_default.vue' /* webpackChunkName: "components/track-default" */).then(c => wrapFunctional(c.default || c))
export const ElementBuilderBkp = () => import('../../components/core/1elementBuilder.bkp.vue' /* webpackChunkName: "components/element-builder-bkp" */).then(c => wrapFunctional(c.default || c))
export const ElementBuilderFunctional = () => import('../../components/core/1elementBuilderFunctional.vue' /* webpackChunkName: "components/element-builder-functional" */).then(c => wrapFunctional(c.default || c))
export const AccountCard = () => import('../../components/core/accountCard.vue' /* webpackChunkName: "components/account-card" */).then(c => wrapFunctional(c.default || c))
export const AddToCart = () => import('../../components/core/addToCart.vue' /* webpackChunkName: "components/add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const AutoSuggest = () => import('../../components/core/autoSuggest.vue' /* webpackChunkName: "components/auto-suggest" */).then(c => wrapFunctional(c.default || c))
export const Availability = () => import('../../components/core/availability.vue' /* webpackChunkName: "components/availability" */).then(c => wrapFunctional(c.default || c))
export const BillingAddress = () => import('../../components/core/billingAddress.vue' /* webpackChunkName: "components/billing-address" */).then(c => wrapFunctional(c.default || c))
export const BookSpecs = () => import('../../components/core/bookSpecs.vue' /* webpackChunkName: "components/book-specs" */).then(c => wrapFunctional(c.default || c))
export const CardModal = () => import('../../components/core/cardModal.vue' /* webpackChunkName: "components/card-modal" */).then(c => wrapFunctional(c.default || c))
export const CartChecklist = () => import('../../components/core/cartChecklist.vue' /* webpackChunkName: "components/cart-checklist" */).then(c => wrapFunctional(c.default || c))
export const CartInvoice = () => import('../../components/core/cartInvoice.vue' /* webpackChunkName: "components/cart-invoice" */).then(c => wrapFunctional(c.default || c))
export const CartRullingFriendly = () => import('../../components/core/cartRullingFriendly.vue' /* webpackChunkName: "components/cart-rulling-friendly" */).then(c => wrapFunctional(c.default || c))
export const CartRullingItems = () => import('../../components/core/cartRullingItems.vue' /* webpackChunkName: "components/cart-rulling-items" */).then(c => wrapFunctional(c.default || c))
export const CatalogFull = () => import('../../components/core/catalogFull.vue' /* webpackChunkName: "components/catalog-full" */).then(c => wrapFunctional(c.default || c))
export const CatalogMap = () => import('../../components/core/catalogMap.vue' /* webpackChunkName: "components/catalog-map" */).then(c => wrapFunctional(c.default || c))
export const CategoriesMap = () => import('../../components/core/categoriesMap.vue' /* webpackChunkName: "components/categories-map" */).then(c => wrapFunctional(c.default || c))
export const Chart = () => import('../../components/core/chart.vue' /* webpackChunkName: "components/chart" */).then(c => wrapFunctional(c.default || c))
export const Check = () => import('../../components/core/check.vue' /* webpackChunkName: "components/check" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/core/contactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const Cookies = () => import('../../components/core/cookies.vue' /* webpackChunkName: "components/cookies" */).then(c => wrapFunctional(c.default || c))
export const CoreOptions = () => import('../../components/core/coreOptions.vue' /* webpackChunkName: "components/core-options" */).then(c => wrapFunctional(c.default || c))
export const CosmoteDeals = () => import('../../components/core/cosmoteDeals.vue' /* webpackChunkName: "components/cosmote-deals" */).then(c => wrapFunctional(c.default || c))
export const Countries = () => import('../../components/core/countries.vue' /* webpackChunkName: "components/countries" */).then(c => wrapFunctional(c.default || c))
export const DesignSystem = () => import('../../components/core/designSystem.vue' /* webpackChunkName: "components/design-system" */).then(c => wrapFunctional(c.default || c))
export const DynamicMap = () => import('../../components/core/dynamicMap.vue' /* webpackChunkName: "components/dynamic-map" */).then(c => wrapFunctional(c.default || c))
export const EKarditsaCard = () => import('../../components/core/eKarditsaCard.vue' /* webpackChunkName: "components/e-karditsa-card" */).then(c => wrapFunctional(c.default || c))
export const EKarditsaMap = () => import('../../components/core/eKarditsaMap.vue' /* webpackChunkName: "components/e-karditsa-map" */).then(c => wrapFunctional(c.default || c))
export const EKarditsaStore = () => import('../../components/core/eKarditsaStore.vue' /* webpackChunkName: "components/e-karditsa-store" */).then(c => wrapFunctional(c.default || c))
export const ElasticBreadcrumbs = () => import('../../components/core/elasticBreadcrumbs.vue' /* webpackChunkName: "components/elastic-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const ElasticDistinct = () => import('../../components/core/elasticDistinct.vue' /* webpackChunkName: "components/elastic-distinct" */).then(c => wrapFunctional(c.default || c))
export const ElasticFilters = () => import('../../components/core/elasticFilters.vue' /* webpackChunkName: "components/elastic-filters" */).then(c => wrapFunctional(c.default || c))
export const ElasticHeader = () => import('../../components/core/elasticHeader.vue' /* webpackChunkName: "components/elastic-header" */).then(c => wrapFunctional(c.default || c))
export const ElasticItems = () => import('../../components/core/elasticItems.vue' /* webpackChunkName: "components/elastic-items" */).then(c => wrapFunctional(c.default || c))
export const ElasticPagination = () => import('../../components/core/elasticPagination.vue' /* webpackChunkName: "components/elastic-pagination" */).then(c => wrapFunctional(c.default || c))
export const ElasticRouting = () => import('../../components/core/elasticRouting.vue' /* webpackChunkName: "components/elastic-routing" */).then(c => wrapFunctional(c.default || c))
export const ElasticSidePanel = () => import('../../components/core/elasticSidePanel.vue' /* webpackChunkName: "components/elastic-side-panel" */).then(c => wrapFunctional(c.default || c))
export const ElementBuilder = () => import('../../components/core/elementBuilder.vue' /* webpackChunkName: "components/element-builder" */).then(c => wrapFunctional(c.default || c))
export const ElementBuilderContainer = () => import('../../components/core/elementBuilderContainer.vue' /* webpackChunkName: "components/element-builder-container" */).then(c => wrapFunctional(c.default || c))
export const Field = () => import('../../components/core/field.vue' /* webpackChunkName: "components/field" */).then(c => wrapFunctional(c.default || c))
export const FilterPanel = () => import('../../components/core/filterPanel.vue' /* webpackChunkName: "components/filter-panel" */).then(c => wrapFunctional(c.default || c))
export const FilterPills = () => import('../../components/core/filterPills.vue' /* webpackChunkName: "components/filter-pills" */).then(c => wrapFunctional(c.default || c))
export const GoogleAutocomplete = () => import('../../components/core/googleAutocomplete.vue' /* webpackChunkName: "components/google-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const GoogleMap = () => import('../../components/core/googleMap.vue' /* webpackChunkName: "components/google-map" */).then(c => wrapFunctional(c.default || c))
export const GoogleMapsAutocomplete = () => import('../../components/core/googleMapsAutocomplete.vue' /* webpackChunkName: "components/google-maps-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const GoogleOneTap = () => import('../../components/core/googleOneTap.vue' /* webpackChunkName: "components/google-one-tap" */).then(c => wrapFunctional(c.default || c))
export const Guest = () => import('../../components/core/guest.vue' /* webpackChunkName: "components/guest" */).then(c => wrapFunctional(c.default || c))
export const HorizontalProducts = () => import('../../components/core/horizontalProducts.vue' /* webpackChunkName: "components/horizontal-products" */).then(c => wrapFunctional(c.default || c))
export const HyperLink = () => import('../../components/core/hyperLink.vue' /* webpackChunkName: "components/hyper-link" */).then(c => wrapFunctional(c.default || c))
export const HyperSwitch = () => import('../../components/core/hyperSwitch.vue' /* webpackChunkName: "components/hyper-switch" */).then(c => wrapFunctional(c.default || c))
export const Indexing = () => import('../../components/core/indexing.vue' /* webpackChunkName: "components/indexing" */).then(c => wrapFunctional(c.default || c))
export const InputError = () => import('../../components/core/inputError.vue' /* webpackChunkName: "components/input-error" */).then(c => wrapFunctional(c.default || c))
export const Invoice = () => import('../../components/core/invoice.vue' /* webpackChunkName: "components/invoice" */).then(c => wrapFunctional(c.default || c))
export const Iordanidhs = () => import('../../components/core/iordanidhs.vue' /* webpackChunkName: "components/iordanidhs" */).then(c => wrapFunctional(c.default || c))
export const ItemsHeader = () => import('../../components/core/itemsHeader.vue' /* webpackChunkName: "components/items-header" */).then(c => wrapFunctional(c.default || c))
export const ItemsPagination = () => import('../../components/core/itemsPagination.vue' /* webpackChunkName: "components/items-pagination" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/core/loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../components/core/login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const NativeOrders = () => import('../../components/core/nativeOrders.vue' /* webpackChunkName: "components/native-orders" */).then(c => wrapFunctional(c.default || c))
export const NewAddress = () => import('../../components/core/newAddress.vue' /* webpackChunkName: "components/new-address" */).then(c => wrapFunctional(c.default || c))
export const NewsLetter = () => import('../../components/core/newsLetter.vue' /* webpackChunkName: "components/news-letter" */).then(c => wrapFunctional(c.default || c))
export const OperatorRules = () => import('../../components/core/operatorRules.vue' /* webpackChunkName: "components/operator-rules" */).then(c => wrapFunctional(c.default || c))
export const Options = () => import('../../components/core/options.vue' /* webpackChunkName: "components/options" */).then(c => wrapFunctional(c.default || c))
export const OptionsDefaults = () => import('../../components/core/optionsDefaults.vue' /* webpackChunkName: "components/options-defaults" */).then(c => wrapFunctional(c.default || c))
export const OptionsDefaultsFallback = () => import('../../components/core/optionsDefaultsFallback.vue' /* webpackChunkName: "components/options-defaults-fallback" */).then(c => wrapFunctional(c.default || c))
export const OptionsRequired = () => import('../../components/core/optionsRequired.vue' /* webpackChunkName: "components/options-required" */).then(c => wrapFunctional(c.default || c))
export const Payment = () => import('../../components/core/payment.vue' /* webpackChunkName: "components/payment" */).then(c => wrapFunctional(c.default || c))
export const PaymentSelect = () => import('../../components/core/paymentSelect.vue' /* webpackChunkName: "components/payment-select" */).then(c => wrapFunctional(c.default || c))
export const Pending = () => import('../../components/core/pending.vue' /* webpackChunkName: "components/pending" */).then(c => wrapFunctional(c.default || c))
export const Preview = () => import('../../components/core/preview.vue' /* webpackChunkName: "components/preview" */).then(c => wrapFunctional(c.default || c))
export const Product = () => import('../../components/core/product.vue' /* webpackChunkName: "components/product" */).then(c => wrapFunctional(c.default || c))
export const ProductHyperElements = () => import('../../components/core/productHyperElements.vue' /* webpackChunkName: "components/product-hyper-elements" */).then(c => wrapFunctional(c.default || c))
export const Rating = () => import('../../components/core/rating.vue' /* webpackChunkName: "components/rating" */).then(c => wrapFunctional(c.default || c))
export const Register = () => import('../../components/core/register.vue' /* webpackChunkName: "components/register" */).then(c => wrapFunctional(c.default || c))
export const Reviews = () => import('../../components/core/reviews.vue' /* webpackChunkName: "components/reviews" */).then(c => wrapFunctional(c.default || c))
export const ScssInit = () => import('../../components/core/scssInit.vue' /* webpackChunkName: "components/scss-init" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/core/search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/core/searchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchDefault = () => import('../../components/core/searchDefault.vue' /* webpackChunkName: "components/search-default" */).then(c => wrapFunctional(c.default || c))
export const SearchSuggestions = () => import('../../components/core/searchSuggestions.vue' /* webpackChunkName: "components/search-suggestions" */).then(c => wrapFunctional(c.default || c))
export const SeoTitle = () => import('../../components/core/seoTitle.vue' /* webpackChunkName: "components/seo-title" */).then(c => wrapFunctional(c.default || c))
export const Shipping = () => import('../../components/core/shipping.vue' /* webpackChunkName: "components/shipping" */).then(c => wrapFunctional(c.default || c))
export const ShippingCost = () => import('../../components/core/shippingCost.vue' /* webpackChunkName: "components/shipping-cost" */).then(c => wrapFunctional(c.default || c))
export const ShippingSelect = () => import('../../components/core/shippingSelect.vue' /* webpackChunkName: "components/shipping-select" */).then(c => wrapFunctional(c.default || c))
export const SideMenuDynamic = () => import('../../components/core/sideMenu_dynamic.vue' /* webpackChunkName: "components/side-menu-dynamic" */).then(c => wrapFunctional(c.default || c))
export const SideMenuDynamicDesaturated = () => import('../../components/core/sideMenu_dynamic_desaturated.vue' /* webpackChunkName: "components/side-menu-dynamic-desaturated" */).then(c => wrapFunctional(c.default || c))
export const Speaking = () => import('../../components/core/speaking.vue' /* webpackChunkName: "components/speaking" */).then(c => wrapFunctional(c.default || c))
export const SpecTable = () => import('../../components/core/specTable.vue' /* webpackChunkName: "components/spec-table" */).then(c => wrapFunctional(c.default || c))
export const Specific = () => import('../../components/core/specific.vue' /* webpackChunkName: "components/specific" */).then(c => wrapFunctional(c.default || c))
export const Specs = () => import('../../components/core/specs.vue' /* webpackChunkName: "components/specs" */).then(c => wrapFunctional(c.default || c))
export const StripeElement = () => import('../../components/core/stripeElement.vue' /* webpackChunkName: "components/stripe-element" */).then(c => wrapFunctional(c.default || c))
export const Swipe = () => import('../../components/core/swipe.vue' /* webpackChunkName: "components/swipe" */).then(c => wrapFunctional(c.default || c))
export const Swiper = () => import('../../components/core/swiper.vue' /* webpackChunkName: "components/swiper" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/core/tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const Testcomp = () => import('../../components/core/testcomp.vue' /* webpackChunkName: "components/testcomp" */).then(c => wrapFunctional(c.default || c))
export const Transitioner = () => import('../../components/core/transitioner.vue' /* webpackChunkName: "components/transitioner" */).then(c => wrapFunctional(c.default || c))
export const UserExtra = () => import('../../components/core/userExtra.vue' /* webpackChunkName: "components/user-extra" */).then(c => wrapFunctional(c.default || c))
export const VHtml = () => import('../../components/core/vHtml.vue' /* webpackChunkName: "components/v-html" */).then(c => wrapFunctional(c.default || c))
export const ElementsContainer = () => import('../../components/core/elements/container.vue' /* webpackChunkName: "components/elements-container" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreButton = () => import('../../components/core/elements/coreButton.vue' /* webpackChunkName: "components/elements-core-button" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreComponent = () => import('../../components/core/elements/coreComponent.vue' /* webpackChunkName: "components/elements-core-component" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreContainer = () => import('../../components/core/elements/coreContainer.vue' /* webpackChunkName: "components/elements-core-container" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreDropdown = () => import('../../components/core/elements/coreDropdown.vue' /* webpackChunkName: "components/elements-core-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreEmbed = () => import('../../components/core/elements/coreEmbed.vue' /* webpackChunkName: "components/elements-core-embed" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreHtml = () => import('../../components/core/elements/coreHtml.vue' /* webpackChunkName: "components/elements-core-html" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreImage = () => import('../../components/core/elements/coreImage.vue' /* webpackChunkName: "components/elements-core-image" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreInput = () => import('../../components/core/elements/coreInput.vue' /* webpackChunkName: "components/elements-core-input" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreMenu = () => import('../../components/core/elements/coreMenu.vue' /* webpackChunkName: "components/elements-core-menu" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreRowBkp = () => import('../../components/core/elements/coreRow-bkp.vue' /* webpackChunkName: "components/elements-core-row-bkp" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreRow = () => import('../../components/core/elements/coreRow.vue' /* webpackChunkName: "components/elements-core-row" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreSlider = () => import('../../components/core/elements/coreSlider.vue' /* webpackChunkName: "components/elements-core-slider" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreSpacer = () => import('../../components/core/elements/coreSpacer.vue' /* webpackChunkName: "components/elements-core-spacer" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreText = () => import('../../components/core/elements/coreText.vue' /* webpackChunkName: "components/elements-core-text" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreVideo = () => import('../../components/core/elements/coreVideo.vue' /* webpackChunkName: "components/elements-core-video" */).then(c => wrapFunctional(c.default || c))
export const ElementsCoreWizard = () => import('../../components/core/elements/coreWizard.vue' /* webpackChunkName: "components/elements-core-wizard" */).then(c => wrapFunctional(c.default || c))
export const ElementsData = () => import('../../components/core/elements/data.vue' /* webpackChunkName: "components/elements-data" */).then(c => wrapFunctional(c.default || c))
export const ElementsDivider = () => import('../../components/core/elements/divider.vue' /* webpackChunkName: "components/elements-divider" */).then(c => wrapFunctional(c.default || c))
export const ElementsGrid = () => import('../../components/core/elements/grid.vue' /* webpackChunkName: "components/elements-grid" */).then(c => wrapFunctional(c.default || c))
export const ElementsHtml = () => import('../../components/core/elements/html.vue' /* webpackChunkName: "components/elements-html" */).then(c => wrapFunctional(c.default || c))
export const ElementsHyperText = () => import('../../components/core/elements/hyperText.vue' /* webpackChunkName: "components/elements-hyper-text" */).then(c => wrapFunctional(c.default || c))
export const ElementsHyperText2 = () => import('../../components/core/elements/hyperText2.vue' /* webpackChunkName: "components/elements-hyper-text2" */).then(c => wrapFunctional(c.default || c))
export const ElementsIconBoxes = () => import('../../components/core/elements/iconBoxes.vue' /* webpackChunkName: "components/elements-icon-boxes" */).then(c => wrapFunctional(c.default || c))
export const ElementsImageRow = () => import('../../components/core/elements/imageRow.vue' /* webpackChunkName: "components/elements-image-row" */).then(c => wrapFunctional(c.default || c))
export const ElementsLinks = () => import('../../components/core/elements/links.vue' /* webpackChunkName: "components/elements-links" */).then(c => wrapFunctional(c.default || c))
export const ElementsList = () => import('../../components/core/elements/list.vue' /* webpackChunkName: "components/elements-list" */).then(c => wrapFunctional(c.default || c))
export const ElementsMedia = () => import('../../components/core/elements/media.vue' /* webpackChunkName: "components/elements-media" */).then(c => wrapFunctional(c.default || c))
export const ElementsNewsletter = () => import('../../components/core/elements/newsletter.vue' /* webpackChunkName: "components/elements-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ElementsOrders = () => import('../../components/core/elements/orders.vue' /* webpackChunkName: "components/elements-orders" */).then(c => wrapFunctional(c.default || c))
export const ElementsParagraph = () => import('../../components/core/elements/paragraph.vue' /* webpackChunkName: "components/elements-paragraph" */).then(c => wrapFunctional(c.default || c))
export const ElementsPrefixMedia = () => import('../../components/core/elements/prefixMedia.vue' /* webpackChunkName: "components/elements-prefix-media" */).then(c => wrapFunctional(c.default || c))
export const ElementsPrefixRow = () => import('../../components/core/elements/prefixRow.vue' /* webpackChunkName: "components/elements-prefix-row" */).then(c => wrapFunctional(c.default || c))
export const ElementsRow = () => import('../../components/core/elements/row.vue' /* webpackChunkName: "components/elements-row" */).then(c => wrapFunctional(c.default || c))
export const ElementsSimpleHeader = () => import('../../components/core/elements/simpleHeader.vue' /* webpackChunkName: "components/elements-simple-header" */).then(c => wrapFunctional(c.default || c))
export const ElementsSlider = () => import('../../components/core/elements/slider.vue' /* webpackChunkName: "components/elements-slider" */).then(c => wrapFunctional(c.default || c))
export const ElementsSliderAnimated = () => import('../../components/core/elements/sliderAnimated.vue' /* webpackChunkName: "components/elements-slider-animated" */).then(c => wrapFunctional(c.default || c))
export const ElementsStatistics = () => import('../../components/core/elements/statistics.vue' /* webpackChunkName: "components/elements-statistics" */).then(c => wrapFunctional(c.default || c))
export const ElementsSticky = () => import('../../components/core/elements/sticky.vue' /* webpackChunkName: "components/elements-sticky" */).then(c => wrapFunctional(c.default || c))
export const ElementsTable = () => import('../../components/core/elements/table.vue' /* webpackChunkName: "components/elements-table" */).then(c => wrapFunctional(c.default || c))
export const ElementsTitle = () => import('../../components/core/elements/title.vue' /* webpackChunkName: "components/elements-title" */).then(c => wrapFunctional(c.default || c))
export const RapidItemSelect = () => import('../../components/core/rapid/itemSelect.vue' /* webpackChunkName: "components/rapid-item-select" */).then(c => wrapFunctional(c.default || c))
export const RapidUserSelect = () => import('../../components/core/rapid/userSelect.vue' /* webpackChunkName: "components/rapid-user-select" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
